import * as _ from 'lodash';
import styles from '../../../styles/views/specifications/SpecificationSetItems.scss';
import {
  __,
  getLocalized,
  redirect,
  setTableParams,
  toBoolean,
  unformatMoney,
  validateForm,
} from '../../../core/utils';
import classNames from 'classnames';
import BaseTable from 'react-base-table';
import AutoSizer from 'react-virtualized-auto-sizer';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Tooltip from '../../../components/Tooltip';
import { withRouter } from 'react-router';
import TableNA from '../../../components/TableNA';
import Image from '../../../components/Image';
import SearchableDropdown from '../../../components/SearchableDropdown';
import {
  readWorkingSetSpecification,
  updateSpecificationPrice,
  updateSpecificationProductAttributesGroupFields,
  updateSpecificationReadyForDelivery,
  updateSpecificationToolbar,
} from '../../../redux/actions/table/working_set_specifications';
import NumericInput from './NumericInput';
import ColorPicker from '../../../components/ColorPicker';
import MaterialPicker from '../../../components/MaterialPicker';
import MultiSelect from '../../../components/MultiSelect';
import {
  addBOQPosition,
  updateBOQCalculationQuantity,
  updateBOQCalculationUnit,
  updateBOQPositionArea,
  updateBOQPositionName,
  updateBOQPositionQuantity,
} from '../../../redux/actions/table/working_set_boq';
import queryString from 'query-string';

import {
  attachSpecificationAttributeFieldToSpecificationSetItem,
  deleteSpecificationSetItems,
  updateGenericSpecificationProductAttributesGroupFields,
  updateSpecificationSetFixedColumn,
  updateSpecificationSetItemComment,
} from '../../../redux/actions/table/specification_sets';
import Dropdown from '../../../components/Dropdown';
import { ContextMenu, MenuItem, showMenu } from 'react-contextmenu';
import { hideDropdown } from '../../../redux/actions/general/dropdown';
import RoomChooser from '../../../components/RoomChooser/RoomChooser';
import Filter from './Filter';
import {
  hideAlertbox,
  showAlertbox,
} from '../../../redux/actions/general/alertbox';
import TextareaAutosize from 'react-autosize-textarea';
import Row from './Row';

// ICONS
import UncheckedIcon from '../../../assets/images/unchecked-15x15.svg';
import NeutralIcon from '../../../assets/images/neutral-15x15.svg';
import CheckedIcon from '../../../assets/images/checked-15x15.svg';
import ExpandIcon from './ExpandIcon';
import ArrowDownSmallIcon from '../../../assets/images/arrow-down-small-15x15.svg';
import ConvertSpecificationIcon from '../../../assets/images/convert-to-specification-16x16.svg';
import MenuVIcon from '../../../assets/images/menu-v-15x15.svg';
import MoveIcon from '../../../assets/images/move-16x16.svg';
import ArrowDoubleRightSmallIcon from '../../../assets/images/arrow-double-right-small-15x15.svg';
import EditIcon from '../../../assets/images/edit-16x16.svg';
import LinkIcon from '../../../assets/images/link-new-16x16.svg';
import CommentIconSmallIcon from '../../../assets/images/comments-16x16.svg';
import ButtonGroup from '../../../components/ButtonGroup';
import Button from '../../../components/Button';

const TableSpecificationSetItems = ({
  focusedId,
  setFocusedId,
  data,
  refreshData,
  setRef,
  setNameRef,
  localization,
  tableRef,
  setExpandedRows,
  expandedRows,
  name,
  languageId,
  title,
  possible_address_book_manufacturers,
  possible_address_book_suppliers,
  possible_specification_responsible_users,
  disabledColumns,
  dynamicColumns,
  specificationActionRow,
  dropdown,
  spaceAllocationData,
  disabled,
  filterData,
  active_level,
  setActiveLevel,
  levels,
  groupActions,
  selectedRows,
  ...props
}) => {
  const {
    specification_groups,
    specification_subgroups,
    units,
    product_attribute_fields,
    unit_groups,
  } = localization;

  const [selectedItemContext, setSelectedItemContext] = useState({});

  const [activeComponent, setActiveComponent] = useState('');

  const [checked, setChecked] = useState([]);
  const [isUnallocatedCollapsed, setUnallocatedCollapsed] = useState(true);

  const [areaExpandedAll, setAreaExpandAll] = useState(false);

  const search = useRef();

  useEffect(() => {
    if (_.isEmpty(selectedRows)) {
      setChecked([]);
    }
  }, [selectedRows]);

  const getDeepIds = useCallback((rows) => {
    const ids = [];

    const process = (rows) =>
      _.each(rows, (row) => {
        row.id != 'UN' && _.isEmpty(row.children) && ids.push(row.id);

        !_.isEmpty(row.children) && process(row.children);
      });

    process(rows);

    return ids;
  }, []);

  const getSelectedRows = useCallback((rows) => {
    let result = _.cloneDeep(rows);

    function filterCheckedNotFalse(item) {
      if (item.children && Array.isArray(item.children)) {
        item.children = _.map(
          _.filter(
            item.children,
            (child) => !('checked' in child) || child.checked !== false
          ),
          (row) => filterCheckedNotFalse(row)
        );
      }

      return data;
    }

    return _.map(
      _.filter(result, (item) => item.checked != false),
      (item) => filterCheckedNotFalse(item)
    );
  }, []);

  const final_data = useMemo(() => {
    const rows = isUnallocatedCollapsed
      ? _.reject(data, (row) => _.includes(row.id, 'UN-'))
      : data;

    const process = (rows) =>
      _.map(rows, (row) => {
        let is_checked = false;

        if (_.isEmpty(row.children)) {
          if (_.includes(checked, row.id)) {
            is_checked = true;
          }
        } else {
          const child_ids = getDeepIds(row.children);
          const checked_child_ids = _.intersection(checked, child_ids);

          if (checked_child_ids.length != 0) {
            if (child_ids.length == checked_child_ids.length) {
              is_checked = true;
            } else {
              is_checked = 'neutral';
            }
          }
        }

        return {
          ...row,
          checked: is_checked,
          children: _.isEmpty(row.children) ? undefined : process(row.children),
        };
      });

    return process(rows);
  }, [isUnallocatedCollapsed, data, checked]);

  const data_ids = useMemo(() => getDeepIds(final_data), [final_data]);

  const selected_rows = useMemo(
    () => getSelectedRows(final_data),
    [final_data]
  );

  const _handleMasterExpand = (
    expanded_rows,
    unallocated_collapsed,
    active_level
  ) => {
    setExpandedRows(expanded_rows); //set expanded rows in local state
    setActiveLevel(active_level); //set expanded rows in local state
    setUnallocatedCollapsed(unallocated_collapsed); //collapse 'Unallocated' group
    tableRef.setExpandedRowKeys(expanded_rows); //set expanded rows in dependency state
  };

  const _handleCheckbox = useCallback(
    (id) => {
      const target_ids = _.filter(data_ids, (child_id) =>
        _.startsWith(child_id, id)
      );

      const intersected = _.intersection(target_ids, checked).length;

      if (intersected == 0 || intersected != target_ids.length) {
        setChecked(_.union(checked, target_ids)); //select all
      } else {
        setChecked(_.difference(checked, target_ids)); //select none
      }
    },
    [data_ids, checked]
  );

  const _handleMasterCheckbox = useCallback(() => {
    if (data_ids.length == checked.length && checked.length !== 0) {
      setChecked([]);
    } else {
      setChecked(data_ids);
    }
  }, [data_ids, checked]);

  const _handleFixedDataSubmit = (rowData, changes = {}) => {
    let formData = {
      id: rowData.id,
      team_id: rowData.team_id,
      description: rowData.description,
      manufacturer_company_id: rowData.manufacturer_company_id,
      supplier_company_id: rowData.supplier_company_id,
      responsible_user_id: rowData.responsible_user_id,
      unit_id: changes.unit_id || rowData.unit_id,
      quantity: changes.quantity || rowData.quantity,
      price: changes.price || rowData.price,
    };

    let action = null; // Initialize action

    if (rowData.type === 'specification' || rowData.level == 2) {
      if (changes.unit_id) {
        action = updateBOQCalculationUnit(
          rowData.boq_calculation_id,
          changes.unit_id
        );
      } else if (changes.price) {
        action = updateSpecificationPrice(rowData.specification_id, {
          price: changes.price,
        });
      } else if (changes.quantity) {
        const [specification_subgroup_id, specification_id, position_id] =
          _.split(rowData.id, '-', 3);
        action = position_id
          ? updateBOQPositionQuantity(position_id, changes.quantity)
          : updateBOQCalculationQuantity(
              rowData.boq_calculation_id,
              changes.quantity
            );
      }
    }

    if (!action) {
      action = updateSpecificationSetFixedColumn(
        _.last(formData.id.split('-')),
        formData
      );
    }

    return validateForm(
      action.then(() => {
        refreshData();
      })
    );
  };

  const _handleDynamicDataSubmit = (
    id,
    type,
    specification_product_attribute_group_id,
    value,
    rowData,
    column_id
  ) => {
    let formData = {};

    const fieldName = `attr-${id}`;
    if (type == 'string') {
      formData[fieldName] = _.size(value) > 0 ? value : {};
    } else {
      formData[fieldName] = value;
    }

    let action;

    if (specification_product_attribute_group_id) {
      action = updateSpecificationProductAttributesGroupFields(
        specification_product_attribute_group_id,
        formData
      );
    } else {
      action = updateGenericSpecificationProductAttributesGroupFields(
        _.last(rowData.id.split('-')),
        column_id,
        formData
      );
    }

    return validateForm(
      action.then(() => {
        refreshData();
      })
    );
  };

  const _handleAttachSpecificationAttributeFieldToItem = (
    dynamic_column_id,
    specification_attribute_field_id,
    item_id
  ) => {
    return validateForm(
      attachSpecificationAttributeFieldToSpecificationSetItem(
        dynamic_column_id,
        item_id,
        { specification_attribute_field_id }
      ).then(() => {
        hideDropdown();

        refreshData();
      })
    );
  };

  const _setURLParams = (params) => {
    const search = queryString.stringify({
      ...queryString.parse(props.history.location.search),
      ...params,
    });
    redirect({ search }, true);
  };

  const _handleCommentChange = useCallback((id, comment) => {
    const calculation_id = _.last(_.split(id, '-'));

    updateSpecificationSetItemComment(calculation_id, { comment }).then(() =>
      refreshData()
    );

    hideDropdown();
  }, []);

  const _handleDeleteSpecificationItem = useCallback((id) => {
    deleteSpecificationSetItems([_.last(_.split(id, '-'))]).then(() =>
      refreshData()
    );
  }, []);

  // const _handleUnitChange = useCallback((formData, unit_id) => {
  //   const action =
  //     formData.type === 'generic_specification'
  //       ? _handleFixedDataSubmit({ ...formData, price })
  //       : updateBOQCalculationUnit(formData.boq_calculation_id, unit_id);

  //   action.then(() => {
  //     refreshData();
  //   });
  // }, []);

  // const _handlePriceChange = useCallback((formData, price) => {
  //   const action =
  //     formData.type === 'generic_specification'
  //       ? _handleFixedDataSubmit({ ...formData, price })
  //       : updateSpecificationPrice(_.last(_.split(formData.id, '-')), {
  //           price,
  //         });

  //   action.then(() => {
  //     refreshData();
  //   });
  // }, []);

  // const _handleQuantityChange = useCallback((rowData, quantity) => {
  //   if (rowData.type == 'generic_specification') {
  //     _handleFixedDataSubmit({ ...rowData, quantity });
  //   } else {
  //     const [specification_subgroup_id, specification_id, position_id] =
  //       _.split(rowData.id, '-', 3);

  //     let action = position_id
  //       ? updateBOQPositionQuantity(position_id, quantity)
  //       : updateBOQCalculationQuantity(rowData.boq_calculation_id, quantity);

  //     return action.then(() => refreshData());
  //   }
  // }, []);

  const _handleAreaChange = useCallback((id, values) => {
    const { type: area_type, id: area_id, count: area_count } = values || {};

    const position_id = _.last(_.split(id, '-', 3));

    updateBOQPositionArea(position_id, area_type, area_id, area_count).then(
      () => refreshData()
    );

    hideDropdown();
  }, []);

  const _handleStatusChange = useCallback((rowData, status, comment) => {
    const action = () =>
      updateSpecificationToolbar(rowData.specification_id, {
        status: status,
      }).then(() => {
        refreshData();

        hideAlertbox();
        hideDropdown();
      });

    if (rowData.status === status) return;

    if (status === 'canceled')
      return showAlertbox({
        color: 'red',
        title: __('general.alert.are-you-sure'),
        description: `${__(
          'specifications.working-set.status.alert.first-warning'
        )}\n\n${__('specifications.working-set.status.alert.second-warning')}`,
        buttons: [
          {
            color: 'lightGray',
            text: __('general.alert.no-close'),
            onClick: 'close',
          },
          {
            color: 'gray',
            text: __('general.alert.yes-change-status'),
            onClick: () => action(),
          },
        ],
      });

    if (status === 'ready_for_delivery') {
      updateSpecificationReadyForDelivery(rowData.specification_id, {
        ready_for_delivery: 1,
      });

      setTableParams('working_set_specifications', {
        edit_specification_revision_description_wizard:
          rowData.specification_id,
      });
    }

    if (
      status !== 'ready_for_delivery' &&
      rowData.status === 'ready_for_delivery'
    ) {
      updateSpecificationReadyForDelivery(rowData.specification_id, {
        ready_for_delivery: 0,
      });
    }

    return action();
  }, []);

  const _handleAddPosition = (boq_calculation_id) => {
    return addBOQPosition(boq_calculation_id).then(() => {
      refreshData();
    });
  };

  let space_allocation = useMemo(() => {
    const space_allocation = [];

    _.each(spaceAllocationData.sites, (site) =>
      space_allocation.push({
        id: site.id,
        type: 'site',
        display_name: getLocalized(site.title, languageId),
        parent_type: null,
        parent_id: null,
      })
    );

    _.each(spaceAllocationData.buildings, (building) =>
      space_allocation.push({
        id: building.id,
        type: 'building',
        display_name: getLocalized(building.title, languageId),
        parent_type: building.site_id ? 'site' : null,
        parent_id: building.site_id ? building.site_id : null,
      })
    );

    _.each(spaceAllocationData.storeys, (storey) =>
      space_allocation.push({
        id: storey.id,
        type: 'storey',
        display_name: getLocalized(storey.name, languageId),
        parent_type: storey.building_id ? 'building' : null,
        parent_id: storey.building_id ? storey.building_id : null,
      })
    );

    _.each(spaceAllocationData.rooms, (room) => {
      const find_room_type = _.find(spaceAllocationData.room_types, [
        'id',
        room.room_type_id,
      ]);

      space_allocation.push({
        id: room.id,
        type: 'room',

        display_name: (
          <Tooltip
            text={
              find_room_type
                ? `${getLocalized(find_room_type?.title, languageId)} (${_.size(
                    _.filter(spaceAllocationData, [
                      'room_type_id',
                      find_room_type?.id,
                    ])
                  )})`
                : room.code + ': ' + getLocalized(room.name, languageId)
            }
          >
            {room.code + ': ' + getLocalized(room.name, languageId)}
          </Tooltip>
        ),
        parent_type: room.storey_id ? 'storey' : null,
        parent_id: room.storey_id ? room.storey_id : null,
        room_type_id: room.room_type_id,
      });
    });

    _.each(spaceAllocationData.room_types, (room_type) => {
      space_allocation.push({
        id: room_type.id,
        type: 'room_type',
        display_name: `${getLocalized(room_type.title, languageId)} (${_.size(
          _.filter(spaceAllocationData.rooms, ['room_type_id', room_type?.id])
        )})`,
        room_count: _.size(
          _.filter(spaceAllocationData.rooms, ['room_type_id', room_type?.id])
        ),
      });
    });

    return space_allocation;
  }, [languageId, spaceAllocationData]);

  const columns = [
    {
      key: 'name',
      required: true,
      title: 'Title',
      width: 250,
      minWidth: 200,
      resizable: true,
      sortable: false,
      frozen: 'left',
      cellRenderer: ({ rowData }) =>
        rowData.id === 'UN' ? (
          <span>{__('specification-set.table.row.unallocated')}</span>
        ) : (
          <>
            {rowData.checked === true && (
              <CheckedIcon
                className={styles.checked}
                onClick={() => _handleCheckbox(rowData.id)}
              />
            )}
            {rowData.checked === false && (
              <UncheckedIcon
                className={classNames(
                  styles.unchecked,
                  checked.length > 0 && styles.visible
                )}
                onClick={() => _handleCheckbox(rowData.id)}
              />
            )}
            {rowData.checked === 'neutral' && (
              <NeutralIcon
                className={styles.neutral}
                onClick={() => _handleCheckbox(rowData.id)}
              />
            )}

            {/* {!_.includes(rowData.id, 'UN') &&
            (rowData.level < 3 ? (
              <span className={styles.code}>{rowData.code}</span>
            ) : (
              <span className={styles.codePlaceholder} />
            ))} */}

            {/* {!_.includes(rowData.id, 'UN') && (
              <span className={styles.codePlaceholder} />
            )} */}
            {/* <span className={styles.codePlaceholder} /> */}

            {(rowData.level == 0 ||
              (rowData.level == 1 && rowData.specification_id)) && (
              <div
                className={classNames(
                  styles.addRowActions,
                  dropdown && rowData.id == focusedId && styles.fixedOpacity
                )}
              >
                <span
                  onClick={(e) => {
                    const x = e.clientX;
                    const y = e.clientY;

                    const {
                      name,
                      id,
                      level,
                      specification_code_id,
                      boq_calculation_id,
                    } = rowData;

                    setSelectedItemContext({
                      name,
                      id,
                      level,
                      specification_code_id,
                      do_not_delete: true,
                      boq_calculation_id,
                    });

                    showMenu({
                      position: { x, y },
                      id: 'specification_set_items_context_menu',
                    });
                  }}
                >
                  <Tooltip
                    text={__('specification_set_item.table.row.add-boq-item')}
                  >
                    +
                  </Tooltip>
                </span>
              </div>
            )}

            <div className={classNames(styles.nameContainer)}>
              {rowData.specification_id ? (
                <Tooltip text='Go to specification'>
                  <div
                    className={classNames(
                      styles.input,
                      styles.bold,
                      styles.inputSpecification
                    )}
                    onClick={() => {
                      readWorkingSetSpecification(
                        rowData.specification_id
                      ).then(({ response }) => {
                        setTableParams('working_set_specifications', {
                          flyout: response.data,
                          clicked_row: response.data.id,
                        });
                      });
                    }}
                  >
                    {rowData.name}
                    <ArrowDoubleRightSmallIcon />
                  </div>
                </Tooltip>
              ) : (
                <div
                  className={classNames(
                    styles.input,
                    rowData.level != 2 && styles.bold
                  )}
                >
                  {rowData.level == 2 && (
                    <input
                      className={styles.input}
                      type='text'
                      onBlur={({ currentTarget }) => {
                        const [
                          specification_subgroup_id,
                          specification_id,
                          position_id,
                        ] = _.split(rowData.id, '-', 3);

                        updateBOQPositionName(
                          position_id,
                          currentTarget.value,
                          languageId
                        );
                      }}
                      key={rowData.id + _.get(rowData.name, languageId)}
                      defaultValue={_.get(rowData.name, languageId)}
                      maxLength={70}
                    />
                  )}

                  {_.includes([0, 1, 3], rowData.level) && rowData.name}
                </div>
              )}

              {rowData.level == 1 &&
                rowData.type == 'generic_specification' && (
                  <div
                    className={
                      styles.createSpecificationFromGenericSpecification
                    }
                    onClick={() => {
                      setTableParams('specification_sets', {
                        wizard_convert_generic_specification_to_specification:
                          rowData,
                      });
                    }}
                  >
                    <Tooltip
                      text={__(
                        'specification_set_item.table.row.convert-to-specification'
                      )}
                    >
                      <ConvertSpecificationIcon />
                    </Tooltip>
                  </div>
                )}

              {rowData.specification_id && (
                <div
                  className={styles.editSpecificationCode}
                  onClick={() => {
                    if (rowData.level == 1 && rowData.specification_id) {
                      readWorkingSetSpecification(
                        rowData.specification_id
                      ).then(({ response }) => {
                        setTableParams('working_set_specifications', {
                          flyout: response.data,
                          edit_specification_code_wizard: true,
                        });
                      });
                    }
                  }}
                >
                  <EditIcon />
                </div>
              )}
            </div>
          </>
        ),
    },

    {
      key: 'thumb',
      required: true,
      title: 'Thumbnail',
      width: 80,
      resizable: true,
      sortable: false,
      cellRenderer: ({ rowData }) =>
        _.includes([1, 3], rowData.level) &&
        (rowData.thumbnail_url ? (
          <Tooltip
            placement='left'
            text={
              <Image
                className={styles.thumbPreview}
                src={rowData.thumbnail_url}
              />
            }
            html={true}
            dark={true}
          >
            <Image className={styles.thumb} src={rowData.thumbnail_url} />
          </Tooltip>
        ) : (
          <TableNA />
        )),
    },

    {
      key: 'description',
      required: true,
      title: 'Description',
      width: 250,
      resizable: true,
      sortable: false,
      cellRenderer: ({ rowData }) =>
        _.includes([1, 3], rowData.level) && (
          <input
            className={classNames(styles.input)}
            type='text'
            ref={(ref) => setNameRef(rowData.id, ref)}
            onBlur={({ currentTarget }) =>
              _handleFixedDataSubmit({
                ...rowData,
                description: { [languageId]: currentTarget.value },
              })
            }
            key={rowData.id + _.get(rowData.description, languageId)}
            defaultValue={_.get(rowData.description, languageId)}
            maxLength={70}
            readOnly={disabled}
          />
        ),
    },

    {
      key: 'unit',
      title: 'Unit',
      required: true,
      align: 'right',
      width: 70,
      resizable: true,
      sortable: false,
      cellRenderer: ({ rowData }) => {
        // Specification groups
        const unit_options = _.sortBy(
          _.map(units, (unit, i) => {
            const unit_group = _.get(unit_groups, unit.unit_group_id, {});

            return {
              value: unit.id,
              label: unit.symbol,
              group: getLocalized(unit_group.name, languageId),
              priority: unit_group.priority,
            };
          }),
          'priority'
        );

        return _.includes([1, 3], rowData.level) ? (
          <SearchableDropdown
            top={25}
            readOnly={disabled}
            right={-15}
            multiselect={false}
            dropdownWrapperClassName={styles.dropdownWrapper}
            name={'unit.' + rowData.id}
            header={<div className={styles.dropdownHeader}>Unit</div>}
            groupReadOnly
            options={unit_options}
            onSelect={({ value }) => {
              _handleFixedDataSubmit(rowData, { unit_id: value });
            }}
            buttonLabel={__('button.cancel')}
          >
            <div className={styles.dropdown}>
              {rowData.unit_id && (
                <span>{_.get(units, [rowData.unit_id, 'symbol'])}</span>
              )}
              {!rowData.unit_id && <span className={styles.none}>--</span>}
              <ArrowDownSmallIcon />
            </div>
          </SearchableDropdown>
        ) : (
          ''
        );
      },
    },

    {
      key: 'price',
      required: true,
      title: `Price`,
      width: 100,
      align: 'right',
      resizable: true,
      sortable: false,
      cellRenderer: ({ rowData }) => {
        if (_.includes([1, 2, 3], rowData.level)) {
          return (
            <NumericInput
              key={rowData.id + rowData.price}
              defaultValue={rowData.price}
              onBlur={(value) =>
                _handleFixedDataSubmit(rowData, { price: value })
              }
              readOnly={rowData.level == 2 || disabled}
            />
          );
        }
      },
    },

    {
      key: 'quantity',
      required: true,
      title: `Quantity`,
      width: 100,
      align: 'right',
      resizable: true,
      sortable: false,
      cellRenderer: ({ rowData }) => {
        if (_.includes([1, 2, 3], rowData.level)) {
          return (
            <NumericInput
              key={rowData.id + rowData.quantity}
              defaultValue={rowData.quantity}
              onBlur={(value) =>
                _handleFixedDataSubmit(rowData, {
                  quantity: unformatMoney(value),
                })
              }
              readOnly={
                (!_.isEmpty(rowData.children) && rowData.level == 1) || disabled
              }
            />
          );
        }
      },
    },

    {
      key: 'status',
      title: 'Status',
      width: 100,
      resizable: false,
      sortable: false,
      cellRenderer: ({ rowData }) => {
        if (rowData.specification_id) {
          const status = rowData.status;

          const statuses = [
            {
              value: 'active',
              label: __('working_set_specifications.status.active'),
              color: '#9F4FA1',
            },
            {
              value: 'active_25',
              label: __(
                'working_set_specifications.status.active_at_25_percent'
              ),
              color: '#8168B3',
              disabled: _.includes(['canceled'], status),
            },
            {
              value: 'active_50',
              label: __(
                'working_set_specifications.status.active_at_50_percent'
              ),
              color: '#6383C5',
              disabled: _.includes(['canceled'], status),
            },
            {
              value: 'active_75',
              label: __(
                'working_set_specifications.status.active_at_75_percent'
              ),
              color: '#459EB6',
              disabled: _.includes(['canceled'], status),
            },
            {
              value: 'ready_for_delivery',
              label: __('working_set_specifications.status.ready_for_delivery'),
              color: '#96C236',
              disabled: _.includes(['canceled'], status),
            },
            {
              value: 'canceled',
              label: __('working_set_specifications.status.canceled'),
              color: '#B0B1B7',
            },
          ];

          const selectedStatus = _.find(
            statuses,
            (item) => item.value === status
          );

          return (
            <Dropdown
              top={30}
              left={-10}
              leftStyled
              wrapperClassName={styles.dropdownWrapper}
              name={'area.' + rowData.id}
              header={
                <div className={styles.dropdownHeader}>
                  {__('specification_set_item.tooltip.status')}
                </div>
              }
              content={
                <div className={styles.dropdownContent}>
                  {_.map(statuses, (status, i) => (
                    <div
                      key={i}
                      className={classNames(styles.option)}
                      // style={{ color: status.color }}
                      onClick={(e) => {
                        e.stopPropagation();

                        _handleStatusChange(
                          rowData,
                          status.value,
                          rowData.comment
                        );
                      }}
                    >
                      <span>{status.label}</span>

                      <span
                        className={styles.dot}
                        style={{ background: status.color }}
                      />
                    </div>
                  ))}
                </div>
              }
            >
              <div
                className={classNames(styles.dropdownStatus)}
                style={{ background: selectedStatus.color }}
              >
                <div className={styles.button}>
                  <span>{_.find(statuses, ['value', status])?.label}</span>

                  <ArrowDownSmallIcon />
                </div>
              </div>
            </Dropdown>
          );
        }
      },
    },

    {
      key: `comment`,
      title: <CommentIconSmallIcon />,
      width: 50,
      resizable: false,
      sortable: false,
      cellRenderer: ({ rowData }) => {
        if (rowData.level == 1) {
          const commentValue = _.get(rowData, 'comment');

          return (
            <Dropdown
              top={25}
              left={-15}
              readOnly={disabled}
              header={<div className={styles.dropdownHeader}>Comment</div>}
              leftStyled
              wrapperClassName={classNames(
                styles.dropdownWrapper,
                styles.commentWrapper
              )}
              name={'comment.' + rowData.id}
              content={
                <div
                  className={classNames(
                    styles.dropdownContent,
                    styles.commentContent
                  )}
                >
                  <TextareaAutosize
                    onFocus={(e) =>
                      e.currentTarget.setSelectionRange(
                        e.currentTarget.value.length,
                        e.currentTarget.value.length
                      )
                    }
                    autoFocus
                    rows={3}
                    maxRows={15}
                    defaultValue={commentValue}
                    key={'comment.' + rowData.id}
                    maxLength={3000}
                    onBlur={(e) => {
                      e.preventDefault();

                      _handleCommentChange(rowData.id, e.currentTarget.value);
                    }}
                  />
                  <div className={styles.saveFooter}>
                    <ButtonGroup right>
                      <Button
                        lightBlue
                        medium
                        middleText={__('button.done')}
                        onClick={() => {}}
                      />
                    </ButtonGroup>
                  </div>
                </div>
              }
            >
              <div
                className={classNames(
                  styles.comment,
                  commentValue && styles.active
                )}
              >
                <CommentIconSmallIcon />
              </div>
            </Dropdown>
          );
        }
      },
    },

    {
      key: 'area',
      title: 'Area',
      width: 150,
      resizable: false,
      sortable: false,
      cellRenderer: ({ rowData }) => {
        if (rowData.level === 1 && rowData.children) {
          const selected_room_type_ids = _.map(
            _.filter(
              _.get(rowData, 'children'),
              (position) => position.area_type == 'room_type'
            ),
            (position) => position.area_id
          );

          const excluded_room_type_ids = _.map(
            _.filter(spaceAllocationData.rooms, (room) =>
              _.some(
                _.get(rowData, 'children'),
                (position) =>
                  position.area_type == 'room' && position.area_id == room.id
              )
            ),
            (data) => data.room_type_id
          );

          space_allocation = _.map(space_allocation, (area) => {
            return _.find(
              rowData.children,
              (position) =>
                area.type == position.area_type && area.id == position.area_id
            ) ||
              (area.type == 'room_type' &&
                _.includes(excluded_room_type_ids, area.id)) ||
              (area.type == 'room' &&
                _.includes(selected_room_type_ids, area.room_type_id))
              ? { ...area, disabled: true }
              : { ...area, disabled: false };
          });
        }

        if (rowData.level === 2) {
          let area;

          if (rowData.area_id) {
            area =
              _.find(space_allocation, {
                type: rowData.area_type,
                id: rowData.area_id,
              }) || {};
          }

          return (
            <Dropdown
              top={25}
              right={-20}
              wrapperClassName={styles.dropdownWrapper}
              name={'area.' + rowData.id}
              header={
                <div className={styles.dropdownHeader}>
                  {__('table.area-tab.area')}

                  <div className={styles.dropdownHeaderActions}>
                    <span
                      className={styles.dropdownHeaderExpandAll}
                      onClick={() => {
                        setAreaExpandAll(!areaExpandedAll);
                      }}
                    >
                      {__(
                        'working-set-specifications-flyout.quantity.area.expand-all'
                      )}
                    </span>
                    <span
                      className={styles.dropdownHeaderClear}
                      onClick={() =>
                        _handleAreaChange(rowData.id, {
                          type: '',
                          id: null,
                          count: 1,
                        })
                      }
                    >
                      {__(
                        'working-set-specifications-flyout.quantity.area.clear'
                      )}
                    </span>
                  </div>
                </div>
              }
              content={
                <div
                  className={classNames(styles.dropdownContent, styles.space)}
                >
                  <RoomChooser
                    onCancel={hideDropdown}
                    options={space_allocation}
                    onDone={(values) => {
                      _handleAreaChange(rowData.id, {
                        ...values,
                        count:
                          values.type === 'room_type'
                            ? values.room_count
                            : rowData.area_count,
                      });
                    }}
                    initialValues={[]}
                    expandedAllNodes={areaExpandedAll}
                  />
                </div>
              }
            >
              <div className={classNames(styles.dropdown, styles.areaDropdown)}>
                {area ? (
                  <Tooltip text={area.display_name}>
                    <span>{area.display_name}</span>
                  </Tooltip>
                ) : (
                  <span className={styles.none}>--</span>
                )}{' '}
                <ArrowDownSmallIcon />
              </div>
            </Dropdown>
          );
        }
      },
    },

    {
      key: 'manufacturer_company_id',
      required: false,
      title: 'Manufacturer company',
      width: 150,
      resizable: true,
      sortable: false,
      cellRenderer: ({ rowData }) =>
        _.includes([1, 3], rowData.level) && (
          <SearchableDropdown
            top={25}
            right={-15}
            readOnly={disabled}
            multiselect={false}
            dropdownWrapperClassName={styles.dropdownWrapper}
            name={'manufacturer_company_id.' + rowData.id}
            header={
              <div className={styles.dropdownHeader}>Manufacturer company</div>
            }
            options={possible_address_book_manufacturers}
            onSelect={({ value }) => {
              _handleFixedDataSubmit({
                ...rowData,
                manufacturer_company_id: value,
              });
            }}
            buttonLabel={__('button.cancel')}
            noneValue
          >
            <div className={styles.dropdown}>
              {rowData.manufacturer_company_id && (
                <span>
                  {
                    _.find(possible_address_book_manufacturers, {
                      value: rowData.manufacturer_company_id,
                    })?.label
                  }
                </span>
              )}
              {!rowData.manufacturer_company_id && (
                <span className={styles.none}>--</span>
              )}
              <ArrowDownSmallIcon />
            </div>
          </SearchableDropdown>
        ),
    },

    {
      key: 'linked_specifications',
      required: false,
      title: 'Linked specifications',
      width: 160,
      resizable: true,
      sortable: false,
      cellRenderer: ({ rowData }) => {
        if (rowData.specification_id) {
          if (!_.isEmpty(rowData.linked)) {
            return (
              <>
                {_.map(_.take(rowData.linked, 5), (specification) => {
                  const specification_subgroup =
                    _.get(specification_subgroups, [
                      specification.specification_subgroup_id,
                    ]) || {};
                  const specification_group =
                    _.get(
                      specification_groups,
                      _.get(specification_subgroup, 'specification_group_id')
                    ) || {};

                  const specification_code =
                    getLocalized(specification_group.code, languageId, 1) +
                    specification_subgroup.code +
                    _.padStart(specification.code, 2, '0');

                  const specification_title = _.filter(
                    [
                      _.get(specification.description, languageId),
                      specification.model,
                      specification.brand,
                    ],
                    _.identity
                  ).join(' / ');

                  return (
                    <Tooltip
                      key={specification.id}
                      placement='left'
                      text={
                        specification_code +
                        (!_.isEmpty(specification_title)
                          ? ':' + specification_title
                          : '')
                      }
                      html={true}
                      dark={true}
                    >
                      {specification.thumb_url ? (
                        <Image
                          className={styles.thumb}
                          src={specification.thumb_url}
                        />
                      ) : (
                        <div className={styles.thumb}>
                          <LinkIcon />
                        </div>
                      )}
                    </Tooltip>
                  );
                })}

                {_.size(rowData.linked) > 5 && (
                  <Tooltip
                    text={_.map(_.slice(rowData.linked, 5), (specification) => {
                      const specification_subgroup =
                        _.get(specification_subgroups, [
                          specification.specification_subgroup_id,
                        ]) || {};
                      const specification_group =
                        _.get(
                          specification_groups,
                          _.get(
                            specification_subgroup,
                            'specification_group_id'
                          )
                        ) || {};

                      const specification_code =
                        getLocalized(specification_group.code, languageId, 1) +
                        specification_subgroup.code +
                        _.padStart(specification.code, 2, '0');

                      const specification_title = _.filter(
                        [
                          _.get(specification.description, languageId),
                          specification.model,
                          specification.brand,
                        ],
                        _.identity
                      ).join(' / ');

                      return (
                        specification_code +
                        (!_.isEmpty(specification_title)
                          ? ':' + specification_title
                          : '')
                      );
                    }).join('\n')}
                  >
                    <div className={styles.more}>
                      <span>+ {_.size(rowData.linked) - 5}</span>
                    </div>
                  </Tooltip>
                )}
              </>
            );
          } else {
            return <TableNA />;
          }
        }
      },
    },

    {
      key: 'parent_linked_specifications',
      required: false,
      title: 'Parent linked specifications',
      width: 160,
      resizable: true,
      sortable: false,
      cellRenderer: ({ rowData }) => {
        if (rowData.specification_id) {
          if (!_.isEmpty(rowData.parent_linked)) {
            return (
              <>
                {_.map(_.take(rowData.parent_linked, 5), (specification) => {
                  const specification_subgroup =
                    _.get(specification_subgroups, [
                      specification.specification_subgroup_id,
                    ]) || {};
                  const specification_group =
                    _.get(
                      specification_groups,
                      _.get(specification_subgroup, 'specification_group_id')
                    ) || {};

                  const specification_code =
                    getLocalized(specification_group.code, languageId, 1) +
                    specification_subgroup.code +
                    _.padStart(specification.code, 2, '0');

                  const specification_title = _.filter(
                    [
                      _.get(specification.description, languageId),
                      specification.model,
                      specification.brand,
                    ],
                    _.identity
                  ).join(' / ');

                  return (
                    <Tooltip
                      key={specification.id}
                      placement='left'
                      text={
                        specification_code +
                        (!_.isEmpty(specification_title)
                          ? ':' + specification_title
                          : '')
                      }
                      html={true}
                      dark={true}
                    >
                      {specification.thumb_url ? (
                        <Image
                          className={styles.thumb}
                          src={specification.thumb_url}
                        />
                      ) : (
                        <div className={styles.thumb}>
                          <LinkIcon />
                        </div>
                      )}
                    </Tooltip>
                  );
                })}

                {_.size(rowData.parent_linked) > 5 && (
                  <Tooltip
                    text={_.map(
                      _.slice(rowData.parent_linked, 5),
                      (specification) => {
                        const specification_subgroup =
                          _.get(specification_subgroups, [
                            specification.specification_subgroup_id,
                          ]) || {};
                        const specification_group =
                          _.get(
                            specification_groups,
                            _.get(
                              specification_subgroup,
                              'specification_group_id'
                            )
                          ) || {};

                        const specification_code =
                          getLocalized(
                            specification_group.code,
                            languageId,
                            1
                          ) +
                          specification_subgroup.code +
                          _.padStart(specification.code, 2, '0');

                        const specification_title = _.filter(
                          [
                            _.get(specification.description, languageId),
                            specification.model,
                            specification.brand,
                          ],
                          _.identity
                        ).join(' / ');

                        return (
                          specification_code +
                          (!_.isEmpty(specification_title)
                            ? ':' + specification_title
                            : '')
                        );
                      }
                    ).join('\n')}
                  >
                    <div className={styles.more}>
                      <span>+ {_.size(rowData.parent_linked) - 5}</span>
                    </div>
                  </Tooltip>
                )}
              </>
            );
          } else {
            return <TableNA />;
          }
        }
      },
    },

    {
      key: 'supplier_company_id',
      required: false,
      title: 'Supplier company',
      width: 150,
      resizable: true,
      sortable: false,
      cellRenderer: ({ rowData }) =>
        _.includes([1, 3], rowData.level) && (
          <SearchableDropdown
            top={25}
            right={-15}
            readOnly={disabled}
            multiselect={false}
            dropdownWrapperClassName={styles.dropdownWrapper}
            name={'supplier_company_id.' + rowData.id}
            header={
              <div className={styles.dropdownHeader}>Supplier company</div>
            }
            options={possible_address_book_suppliers}
            onSelect={({ value }) => {
              _handleFixedDataSubmit({
                ...rowData,
                supplier_company_id: value,
              });
            }}
            buttonLabel={__('button.cancel')}
            noneValue
          >
            <div className={styles.dropdown}>
              {rowData.supplier_company_id && (
                <span>
                  {
                    _.find(possible_address_book_suppliers, {
                      value: rowData.supplier_company_id,
                    })?.label
                  }
                </span>
              )}
              {!rowData.supplier_company_id && (
                <span className={styles.none}>--</span>
              )}
              <ArrowDownSmallIcon />
            </div>
          </SearchableDropdown>
        ),
    },

    {
      key: 'responsible_user_id',
      required: false,
      title: 'Responsible user',
      width: 150,
      resizable: true,
      sortable: false,
      cellRenderer: ({ rowData }) => {
        return (
          _.includes([1, 3], rowData.level) && (
            <SearchableDropdown
              top={25}
              right={-15}
              readOnly={disabled}
              multiselect={false}
              dropdownWrapperClassName={styles.dropdownWrapper}
              name={'responsible_user_id.' + rowData.id}
              header={
                <div className={styles.dropdownHeader}>Responsible user</div>
              }
              options={_.map(possible_specification_responsible_users, (user) =>
                user.value === rowData.responsible_user_id
                  ? _.merge({}, user, {
                      label: rowData.responsible_user_fullname,
                    })
                  : user
              )}
              onSelect={({ value }) => {
                _handleFixedDataSubmit({
                  ...rowData,
                  responsible_user_id: value,
                });
              }}
              buttonLabel={__('button.cancel')}
            >
              <div className={styles.dropdown}>
                {rowData.responsible_user_id && (
                  <span>
                    {
                      _.find(possible_specification_responsible_users, {
                        value: rowData.responsible_user_id,
                      })?.label
                    }
                  </span>
                )}
                {!rowData.responsible_user_id && (
                  <span className={styles.none}>--</span>
                )}
                <ArrowDownSmallIcon />
              </div>
            </SearchableDropdown>
          )
        );
      },
    },

    ..._.map(dynamicColumns, (column, key) => {
      return {
        key: column.value,
        title: column.label,
        width: 130,
        resizable: true,
        sortable: false,
        cellRenderer: ({ rowData, rowIndex }) => {
          if (
            (rowData.level == 1 || _.includes(rowData.id, 'UN')) &&
            _.get(rowData.dynamic_columns, column.id)
          ) {
            const attribute = _.get(rowData.dynamic_columns, column.id);

            if (attribute?.possible_attribute_fields) {
              return (
                <SearchableDropdown
                  top={25}
                  right={-15}
                  multiselect={false}
                  readOnly={disabled}
                  dropdownWrapperClassName={styles.dropdownWrapper}
                  name={
                    'possible_attribute_fields-' + column.id + '-' + rowData.id
                  }
                  header={
                    <div className={styles.dropdownHeader}>
                      Specification attributes
                    </div>
                  }
                  groupReadOnly
                  options={attribute?.possible_attribute_fields}
                  onSelect={({ value }) => {
                    _handleAttachSpecificationAttributeFieldToItem(
                      column.id,
                      value,
                      _.last(_.split(rowData.id, '-'))
                    );
                  }}
                  buttonLabel={__('button.cancel')}
                >
                  <div className={styles.dropdown}>
                    <span>Select attribute</span>
                    <ArrowDownSmallIcon />
                  </div>
                </SearchableDropdown>
              );
            }

            const boolean_options = [
              {
                label: __('working-set-specifications.attributes-form.yes'),
                value: '1',
              },
              {
                label: __('working-set-specifications.attributes-form.no'),
                value: '0',
              },
            ];

            const currentAttribute = attribute?.template_field_id
              ? _.get(product_attribute_fields, attribute.template_field_id, {})
              : attribute;
            const type = currentAttribute.type;
            let label = getLocalized(currentAttribute.label, languageId);
            label +=
              (attribute.unit_id || currentAttribute.unit_id) &&
              _.includes(['numeric', 'range', 'range_selectable'], type)
                ? ' (' + _.get(units, [attribute.unit_id, 'symbol']) + ')'
                : '';

            if (
              type == 'range_selectable' &&
              attribute.range_from &&
              attribute.range_to
            ) {
              label += ` [${_.toFinite(attribute.range_from)} ~ ${_.toFinite(
                attribute.range_to
              )}]`;
            }

            label += !attribute?.template_field_id ? '*' : '';

            const fieldName = `attr-${attribute.id}-${rowData.id}`;
            const value = attribute.value;

            let unit = _.get(units, [attribute.unit_id, 'symbol']);

            return (
              <>
                {type === 'boolean' && (
                  <SearchableDropdown
                    top={25}
                    right={-15}
                    multiselect={false}
                    dropdownWrapperClassName={styles.dropdownWrapper}
                    name={fieldName}
                    header={
                      <div className={styles.dropdownHeader}>{label}</div>
                    }
                    options={boolean_options}
                    readOnly={disabled}
                    onSelect={({ value }) => {
                      _handleDynamicDataSubmit(
                        attribute.id,
                        type,
                        attribute?.group_id,
                        value,
                        rowData,
                        column.id
                      );
                    }}
                    buttonLabel={__('button.cancel')}
                    noneValue
                  >
                    <div className={styles.dropdown}>
                      <span>
                        {_.get(
                          _.find(boolean_options, { value: attribute.value }),
                          'label'
                        )}
                      </span>

                      <ArrowDownSmallIcon />
                    </div>
                  </SearchableDropdown>
                )}

                {type == 'dropdown_string' && (
                  <SearchableDropdown
                    top={25}
                    right={-15}
                    readOnly={disabled}
                    multiselect={false}
                    dropdownWrapperClassName={styles.dropdownWrapper}
                    name={fieldName}
                    header={
                      <div className={styles.dropdownHeader}>{label}</div>
                    }
                    options={_.map(
                      _.find(product_attribute_fields, [
                        'id',
                        attribute.template_field_id,
                      ])?.options,
                      (option) => ({
                        value: option.id,
                        label: getLocalized(option.value, languageId),
                        disabled: toBoolean(option.discontinued),
                      })
                    )}
                    onSelect={({ value }) => {
                      _handleDynamicDataSubmit(
                        attribute.id,
                        type,
                        attribute?.group_id,
                        value,
                        rowData,
                        column.id
                      );
                    }}
                    buttonLabel={__('button.cancel')}
                    noneValue
                  >
                    <div className={styles.dropdown}>
                      <span>
                        {getLocalized(
                          _.get(
                            _.find(
                              _.find(product_attribute_fields, [
                                'id',
                                attribute.template_field_id,
                              ])?.options,
                              ['id', _.toNumber(value)]
                            ),
                            'value'
                          ),
                          languageId
                        )}
                      </span>

                      <ArrowDownSmallIcon />
                    </div>
                  </SearchableDropdown>
                )}

                {type == 'dropdown_string_multiple' && (
                  <MultiSelect
                    input={{
                      value: value,
                      onChange: (value) => {
                        _handleDynamicDataSubmit(
                          attribute.id,
                          type,
                          attribute?.group_id,
                          value,
                          rowData,
                          column.id
                        );
                      },
                      onBlur: (value) => {
                        setActiveComponent('');
                      },
                      onFocus: () => {
                        setActiveComponent(fieldName);
                      },
                    }}
                    disabled={disabled}
                    meta={{
                      active: activeComponent === fieldName, // Use state to determine if it's active
                    }}
                    options={_.map(
                      _.find(product_attribute_fields, [
                        'id',
                        attribute.template_field_id,
                      ])?.options,
                      (option) => ({
                        value: option.id,
                        label: getLocalized(option.value, languageId),
                        disabled: toBoolean(option.discontinued),
                      })
                    )}
                    hideBarAndAssist
                  />
                )}

                {type == 'dropdown_numeric' && (
                  <SearchableDropdown
                    top={25}
                    readOnly={disabled}
                    right={-15}
                    multiselect={false}
                    dropdownWrapperClassName={styles.dropdownWrapper}
                    name={fieldName}
                    header={
                      <div className={styles.dropdownHeader}>{label}</div>
                    }
                    options={_.map(
                      _.find(product_attribute_fields, [
                        'id',
                        attribute.template_field_id,
                      ]).options,
                      (option) => {
                        let unit = _.get(units, [attribute.unit_id, 'symbol']);

                        return {
                          value: option.id,
                          label:
                            _.toString(getLocalized(option.value, languageId)) +
                            (unit ? ' (' + unit + ')' : ''),
                          disabled: toBoolean(option.discontinued),
                        };
                      }
                    )}
                    onSelect={({ value }) => {
                      _handleDynamicDataSubmit(
                        attribute.id,
                        type,
                        attribute?.group_id,
                        value,
                        rowData,
                        column.id
                      );
                    }}
                    buttonLabel={__('button.cancel')}
                    noneValue
                  >
                    <div className={styles.dropdown}>
                      <span>
                        {getLocalized(
                          _.get(
                            _.find(
                              _.find(product_attribute_fields, [
                                'id',
                                attribute.template_field_id,
                              ])?.options,
                              ['id', _.toNumber(value)]
                            ),
                            'value',
                            ''
                          ),
                          languageId
                        ) + (unit ? ' (' + unit + ')' : '')}
                      </span>

                      <ArrowDownSmallIcon />
                    </div>
                  </SearchableDropdown>
                )}

                {type == 'dropdown_numeric_multiple' && (
                  <MultiSelect
                    input={{
                      value: value,
                      onChange: (value) => {
                        _handleDynamicDataSubmit(
                          attribute.id,
                          type,
                          attribute?.group_id,
                          value,
                          rowData,
                          column.id
                        );
                      },
                      onBlur: (value) => {
                        setActiveComponent('');
                      },
                      onFocus: () => {
                        setActiveComponent(fieldName);
                      },
                    }}
                    disabled={disabled}
                    meta={{
                      active: activeComponent === fieldName, // Use state to determine if it's active
                    }}
                    options={_.map(
                      _.find(product_attribute_fields, [
                        'id',
                        attribute.template_field_id,
                      ])?.options,
                      (option) => {
                        let unit = _.get(units, [attribute.unit_id, 'symbol']);

                        return {
                          value: option.id,
                          label:
                            _.toString(getLocalized(option.value, languageId)) +
                            (unit ? ' (' + unit + ')' : ''),
                          disabled: toBoolean(option.discontinued),
                        };
                      }
                    )}
                    hideBarAndAssist
                  />
                )}

                {type == 'string' && (
                  <input
                    className={styles.input}
                    defaultValue={getLocalized(attribute.value, languageId)}
                    type='text'
                    readOnly={disabled}
                    key={rowData.id}
                    onBlur={({ currentTarget }) => {
                      _handleDynamicDataSubmit(
                        attribute.id,
                        type,
                        attribute?.group_id,
                        { [languageId]: currentTarget.value },
                        rowData,
                        column.id
                      );
                    }}
                  />
                )}

                {_.includes(['numeric', 'range_selectable'], type) && (
                  <NumericInput
                    key={rowData.id}
                    defaultValue={attribute.value}
                    readOnly={disabled}
                    onBlur={(value) => {
                      _handleDynamicDataSubmit(
                        attribute.id,
                        type,
                        attribute?.group_id,
                        unformatMoney(value),
                        rowData,
                        column.id
                      );
                    }}
                  />
                )}

                {type == 'color' && (
                  <ColorPicker
                    topPosition={5}
                    input={{
                      value: value,
                      onChange: (value) => {
                        _handleDynamicDataSubmit(
                          attribute.id,
                          type,
                          attribute?.group_id,
                          value,
                          rowData,
                          column.id
                        );
                      },
                      onBlur: (value) => {
                        setActiveComponent('');
                      },
                      onFocus: () => {
                        setActiveComponent(fieldName);
                      },
                    }}
                    disabled={disabled}
                    meta={{
                      active: activeComponent === fieldName, // Use state to determine if it's active
                    }}
                    noSort
                    hideBarAndAssist
                  />
                )}

                {type == 'material' && (
                  <MaterialPicker
                    topPosition={5}
                    input={{
                      value: value,
                      onChange: (value) => {
                        _handleDynamicDataSubmit(
                          attribute.id,
                          type,
                          attribute?.group_id,
                          value,
                          rowData,
                          column.id
                        );
                      },
                      onBlur: (value) => {
                        setActiveComponent('');
                      },
                      onFocus: () => {
                        setActiveComponent(fieldName);
                      },
                    }}
                    disabled={disabled}
                    meta={{
                      active: activeComponent === fieldName, // Use state to determine if it's active
                    }}
                    hideBarAndAssist
                  />
                )}
              </>
            );
          }
        },
      };
    }),

    {
      key: 'actions',
      title: '',
      width: 35,
      resizable: false,
      sortable: false,
      frozen: 'right',
      required: true,
      cellRenderer: ({ rowData }) => {
        if (rowData.id == 'UN' || rowData.level == 2) return;

        return (
          <div
            className={styles.actions}
            onClick={(e) => {
              const x = e.clientX;
              const y = e.clientY;

              const { name, id, level, specification_code_id } = rowData;

              setSelectedItemContext({
                name,
                id,
                level,
                specification_code_id,
              });

              showMenu({
                position: { x, y },
                id: 'specification_set_items_context_menu',
              });
            }}
          >
            <MenuVIcon
              className={classNames(
                rowData.id === selectedItemContext.id && styles.active
              )}
            />
          </div>
        );
      },
    },

    {
      key: 'placeholder',
      title: '',
      width: 0,
      resizable: false,
      sortable: false,
      frozen: 'right',
      required: true,
    },
  ];

  const headerRenderer = ({ headerIndex, cells, columns }) => {
    if (
      headerIndex === 0 &&
      _.size(cells) > 1 &&
      groupActions &&
      checked.length !== 0
    ) {
      return (
        <div className={styles.groupActions}>
          <div className={styles.actions}>
            {groupActions(
              _.uniq(_.map(checked, (item) => _.split(item, '-')[1]))
            )}
          </div>
        </div>
      );
    } else if (headerIndex === 0) {
      return _.map(columns, (column, i) => {
        if (column.key === 'name') {
          // const levels = {
          //   2: [],
          //   3: [],
          // };

          // _.each(data, (group) => {
          //   !_.isEmpty(group.children) && levels[2].push(group.id);

          //   _.each(group.children, (subgroup) => {
          //     !_.isEmpty(subgroup.children) && levels[3].push(subgroup.id);
          //   });
          // });

          // let active = {};

          // for (let i = 2; i <= 3; i++) {
          //   active[i] = _.every(levels[i], (id) =>
          //     _.includes(expandedRows, id)
          //   );
          // }

          return React.cloneElement(
            cells[i],
            null,
            <>
              <div className='BaseTable__header-cell-text'>
                {checked.length === data_ids.length && checked.length !== 0 && (
                  <CheckedIcon
                    className={classNames(styles.checked, styles.master)}
                    onClick={_handleMasterCheckbox}
                  />
                )}
                {checked.length === 0 && (
                  <UncheckedIcon
                    className={classNames(
                      styles.unchecked,
                      styles.master,
                      checked.length > 0 && styles.visible
                    )}
                    onClick={_handleMasterCheckbox}
                  />
                )}
                {checked.length !== data_ids.length && checked.length !== 0 && (
                  <NeutralIcon
                    className={classNames(styles.neutral, styles.master)}
                    onClick={_handleMasterCheckbox}
                  />
                )}
              </div>
              <div className={styles.masterActions}>
                <Tooltip text='Show group-level'>
                  <span
                    className={classNames(styles.active)}
                    onClick={() => _handleMasterExpand([], true, 1)}
                  >
                    1
                  </span>
                </Tooltip>
                <Tooltip text={_.isEmpty(levels[2]) ? '' : 'Show type-level'}>
                  <span
                    className={classNames(
                      _.includes([2, 3], active_level) && styles.active,
                      _.isEmpty(levels[2]) && styles.disabled
                    )}
                    onClick={() => _handleMasterExpand(levels[2], true, 2)}
                  >
                    2
                  </span>
                </Tooltip>
                <Tooltip text={_.isEmpty(levels[3]) ? '' : 'Show item-level'}>
                  <span
                    className={classNames(
                      active_level == 3 && styles.active,
                      _.isEmpty(levels[3]) && styles.disabled
                    )}
                    onClick={() =>
                      _handleMasterExpand([...levels[2], ...levels[3]], true, 3)
                    }
                  >
                    3
                  </span>
                </Tooltip>
              </div>
              <div className='BaseTable__header-cell-text'>Title</div>
            </>
          );
        } else {
          return React.cloneElement(cells[i], null);
        }
      });
    }
  };

  return (
    <>
      {/* --- --- FILTER --- --- */}
      <Filter
        name={'specification_sets'}
        listAction={refreshData}
        setURLParams={(params) => _setURLParams(params)}
        filterData={filterData}
      />

      <AutoSizer>
        {({ height, width }) => (
          <BaseTable
            ref={(ref) => {
              setRef(ref);

              tableRef = ref;
            }}
            rowClassName={({ rowData }) =>
              classNames(
                rowData.level === 0 && styles.darkGray,
                rowData.level === 1 && styles.lightGray,
                styles.row,
                rowData.id === focusedId && styles.focused,
                rowData.id === focusedId && styles.leftFloatingColumn,
                rowData.id === 'UN' && styles.unallocatedGroup
              )
            }
            fixed
            rowProps={({ rowData, rowIndex }) => ({
              tagName: Row,
              index: rowIndex,
              id: rowData.id,
              unallocatedGroupCollapsed: isUnallocatedCollapsed,
              onClick: () => {
                if (!_.includes(rowData.id, 'UN')) {
                  setFocusedId(rowData.id);
                  setTableParams('specification_sets', {
                    rowIndex,
                    rowData: rowData,
                  });
                }
              },
              level: rowData.level,
              parentId: rowData.parentId,
              setSelectedItemContext: (data) => setSelectedItemContext(data),
              specification_code_id: rowData.specification_code_id,
              boq_calculation_id: rowData.boq_calculation_id,
            })}
            components={{
              ExpandIcon,
            }}
            expandIconProps={({ rowData }) => ({
              depth: rowData.level,
              unallocated_group_collapsed: isUnallocatedCollapsed,
              id: rowData.id,
              unallocated_group_click_handler: () =>
                setUnallocatedCollapsed(!isUnallocatedCollapsed),
            })}
            expandColumnKey='name'
            columns={_.filter(
              columns,
              ({ key, required }) =>
                required || !_.includes(disabledColumns, key)
            )}
            data={final_data}
            width={width}
            height={height}
            onExpandedRowsChange={setExpandedRows}
            ignoreFunctionInColumnCompare={false}
            headerHeight={[32]}
            headerRenderer={headerRenderer}
            headerClassName={({ headerIndex }) => styles.headerRow}
            //estimatedRowHeight={32}
            rowHeight={32}
          />
        )}
      </AutoSizer>

      <ContextMenu
        id='specification_set_items_context_menu'
        className={styles.contextMenu}
        onHide={() => {
          setSelectedItemContext({});
        }}
      >
        {/* --- ADD SPECIFICATIONS - LEVEL 0 --- */}
        {_.get(selectedItemContext, 'level') == 0 && (
          <>
            {_.map(specificationActionRow, (button, i) => {
              return (
                <MenuItem
                  onClick={() => button.onClick(selectedItemContext.id)}
                >
                  <Tooltip text={button.label}>
                    <div className={styles.item}>
                      <span>{button.label}</span>
                    </div>
                  </Tooltip>
                </MenuItem>
              );
            })}
          </>
        )}

        {/* --- SPECIFICATION ROW --- */}
        {_.get(selectedItemContext, 'level') == 1 &&
          _.get(selectedItemContext, 'specification_code_id') && (
            <>
              <MenuItem
                onClick={() =>
                  selectedItemContext?.boq_calculation_id &&
                  _handleAddPosition(
                    _.get(selectedItemContext, 'boq_calculation_id')
                  )
                }
              >
                <Tooltip
                  text={__(
                    'specification_set_item.table.row.action.tooltip.add_boq_position'
                  )}
                >
                  <div
                    className={classNames(
                      styles.item,
                      !selectedItemContext.boq_calculation_id && styles.disabled
                    )}
                  >
                    <span>
                      {__(
                        'specification_set_item.table.row.action.label.add_boq_position'
                      )}
                    </span>
                  </div>
                </Tooltip>
              </MenuItem>

              <MenuItem
                onClick={() =>
                  setTableParams('working_set_specifications', {
                    add_linked_specification_wizard: _.get(
                      selectedItemContext,
                      'specification_code_id'
                    ),
                  })
                }
              >
                <Tooltip
                  text={__(
                    'specification_set_item.table.row.action.tooltip.add_linked_specifications'
                  )}
                >
                  <div className={styles.item}>
                    <span>
                      {__(
                        'specification_set_item.table.row.action.label.add_linked_specifications'
                      )}
                    </span>
                  </div>
                </Tooltip>
              </MenuItem>
            </>
          )}

        {/* --- REMOVE SPECIFICATION / GENERIC SPEC. ROW --- */}
        {_.get(selectedItemContext, 'level') == 1 &&
          !_.get(selectedItemContext, 'do_not_delete') && (
            <>
              <MenuItem
                onClick={() =>
                  _handleDeleteSpecificationItem(
                    _.get(selectedItemContext, 'id')
                  )
                }
              >
                <Tooltip
                  text={__(
                    'specification_set_item.table.row.action.tooltip.remove_item'
                  )}
                >
                  <div className={styles.item}>
                    <span>
                      {__(
                        'specification_set_item.table.row.action.label.remove_item'
                      )}
                    </span>
                  </div>
                </Tooltip>
              </MenuItem>
            </>
          )}

        {/* --- MOVE GENERIC SPECIFICATION --- */}
        {_.includes(_.get(selectedItemContext, 'id'), 'UN-') && (
          <MenuItem
            onClick={() =>
              setTableParams('specification_sets', {
                move_generic_specification_wizard: {
                  ..._.pick(selectedItemContext, ['id', 'name']),
                },
              })
            }
          >
            <Tooltip
              text={__(
                'specification_set_item.table.row.action.tooltip.move_generic_specification_wizard'
              )}
            >
              <div className={styles.item}>
                <span className={styles.icon}>
                  <MoveIcon />
                </span>
                <span>
                  {__(
                    'specification_set_item.table.row.action.label.move_generic_specification_wizard'
                  )}
                </span>
              </div>
            </Tooltip>
          </MenuItem>
        )}
      </ContextMenu>
    </>
  );
};

export default withRouter(TableSpecificationSetItems);
