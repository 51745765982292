import store from '../../../core/store';
import { downloadFile } from '../general/downloader';
import { backendURL } from '../../../core/utils';

export const listAllTasks = (stage_id) => {
  return store.dispatch({
    action: 'table.all_tasks/LIST_ALL_TASKS',
    method: 'GET',
    endpoint: '/stages/' + stage_id + '/tasks',
    main: true,
  });
};

export const createTask = (stage_id, formData) => {
  return store.dispatch({
    action: 'table.all_tasks/CREATE_TASK',
    method: 'POST',
    endpoint: '/stages/' + stage_id + '/tasks',
    data: formData,
  });
};

export const listPossibleTaskAssignee = (stage_id) => {
  return store.dispatch({
    action: 'table.all_tasks/LIST_POSSIBLE_TASK_ASSIGNEE',
    method: 'GET',
    endpoint: '/stages/' + stage_id + '/possible_task_assignee',
  });
};

export const listPossibleTaskFollowers = (stage_id) => {
  return store.dispatch({
    action: 'table.all_tasks/LIST_POSSIBLE_TASK_FOLLOWERS',
    method: 'GET',
    endpoint: '/stages/' + stage_id + '/possible_task_followers',
  });
};

export const readTask = (task_id) => {
  return store.dispatch({
    action: 'table.all_tasks/READ_TASK',
    method: 'GET',
    endpoint: '/tasks/' + task_id,
    has404: true,
  });
};

export const updateTask = (task_id, formData) => {
  return store.dispatch({
    action: 'table.all_tasks/UPDATE_TASK',
    method: 'PUT',
    endpoint: '/tasks/' + task_id,
    data: formData,
  });
};

export const updateTaskSpaceAllocation = (task_id, formData) => {
  return store.dispatch({
    action: 'table.all_tasks/UPDATE_TASK_SPACE_ALLOCATION',
    method: 'PUT',
    endpoint: '/tasks/' + task_id + '/space_allocation',
    data: formData,
  });
};

export const updateTaskToolbar = (task_id, formData) => {
  return store.dispatch({
    action: 'table.all_tasks/UPDATE_TASK_TOOLBAR',
    method: 'PUT',
    endpoint: '/tasks/' + task_id + '/toolbar',
    data: formData,
  });
};

export const addTaskComment = (task_id, message, files) => {
  return store.dispatch({
    action: 'table.all_tasks/ADD_TASK_COMMENT',
    method: 'POST',
    endpoint: '/tasks/' + task_id + '/comments',
    data: {
      message,
      files,
    },
  });
};

export const updateTaskComment = (comment_id, message, files) => {
  return store.dispatch({
    action: 'table.all_tasks/UPDATE_TASK_COMMENT',
    method: 'POST',
    endpoint: '/tasks/comments/' + comment_id,
    data: {
      message,
      files,
    },
  });
};

export const deleteRelatedTask = (task_id, formData) => {
  return store.dispatch({
    action: 'table.all_tasks/DELETE_RELATED_TASK',
    method: 'DELETE',
    endpoint: '/tasks/' + task_id + '/related_tasks',
    data: formData,
  });
};

export const listPossibleRelatedTasks = (task_id) => {
  return store.dispatch({
    action: 'table.all_tasks/LIST_POSSIBLE_RELATED_TASKS',
    method: 'GET',
    endpoint: '/tasks/' + task_id + '/possible_related_tasks',
  });
};

export const readTaskHistory = (task_id) => {
  return store.dispatch({
    action: 'table.all_tasks/READ_TASK_HISTORY',
    method: 'GET',
    endpoint: '/tasks/' + task_id + '/history',
  });
};

export const addRelatedTasks = (task_id, formData) => {
  return store.dispatch({
    action: 'table.all_tasks/ADD_RELATED_TASKS',
    method: 'POST',
    endpoint: '/tasks/' + task_id + '/related_tasks',
    data: formData,
  });
};

export const addTaskChecklistItem = (task_id) => {
  return store.dispatch({
    action: 'table.all_tasks/ADD_TASK_CHECKLIST_ITEM',
    method: 'POST',
    endpoint: '/tasks/' + task_id + '/checklist',
  });
};

export const updateTaskChecklistItemTitle = (checklist_item_id, title) => {
  return store.dispatch({
    action: 'table.all_tasks/UPDATE_TASK_CHECKLIST_ITEM_TITLE',
    method: 'PUT',
    endpoint: '/tasks/checklist_items/' + checklist_item_id,
    data: {
      title,
    },
  });
};

export const updateTaskChecklistItemCompletion = (
  checklist_item_id,
  completion
) => {
  return store.dispatch({
    action: 'table.all_tasks/UPDATE_TASK_CHECKLIST_ITEM_COMPLETION',
    method: 'PUT',
    endpoint: '/tasks/checklist_items/' + checklist_item_id + '/completion',
    data: {
      completion,
    },
  });
};

export const reorderTaskChecklistItems = (task_id, items) => {
  return store.dispatch({
    action: 'table.all_tasks/REORDER_TASK_CHECKLIST_ITEMS',
    method: 'PUT',
    endpoint: '/tasks/' + task_id + '/checklist_items/order',
    data: {
      items,
    },
  });
};

export const deleteTaskChecklistItem = (checklist_item_id) => {
  return store.dispatch({
    action: 'table.all_tasks/DELETE_TASK_CHECKLIST_ITEM',
    method: 'DELETE',
    endpoint: '/tasks/checklist_items/' + checklist_item_id,
  });
};

export const createPlanRevisionTask = (plan_revision_id, formData) => {
  return store.dispatch({
    action: 'table.all_tasks/CREATE_PLAN_REVISION_TASK',
    method: 'POST',
    endpoint: '/plans/revisions/' + plan_revision_id + '/tasks',
    data: formData,
  });
};

export const createPlanVersionTask = (plan_version_id, formData) => {
  return store.dispatch({
    action: 'table.all_tasks/CREATE_PLAN_VERSION_TASK',
    method: 'POST',
    endpoint: '/plans/versions/' + plan_version_id + '/tasks',
    data: formData,
  });
};

export const uploadTaskFiles = (task_id, files) => {
  return store.dispatch({
    action: 'table.all_tasks/UPLOAD_TASK_FILE',
    method: 'POST',
    preloaded: false,
    endpoint: '/tasks/' + task_id + '/files',
    data: {
      files,
    },
  });
};

export const deleteTaskFile = (task_id, attachment_id) => {
  return store.dispatch({
    action: 'table.all_tasks/DELETE_TASK_FILE',
    method: 'DELETE',
    endpoint: '/tasks/' + task_id + '/files',
    data: {
      attachment_id,
    },
  });
};

export const deleteCommentFile = (comment_id, attachment_id) => {
  return store.dispatch({
    action: 'table.all_tasks/DELETE_COMMENT_FILE',
    method: 'DELETE',
    endpoint: '/tasks/comments/' + comment_id + '/files',
    data: {
      attachment_id,
    },
  });
};

export const updateTaskType = (task_id, type) => {
  return store.dispatch({
    action: 'table.all_tasks/UPDATE_TASK_TYPE',
    method: 'PUT',
    endpoint: '/tasks/' + task_id + '/type',
    data: {
      type,
    },
  });
};

export const updateTaskStatus = (task_id, status) => {
  return store.dispatch({
    action: 'table.all_tasks/UPDATE_TASK_STATUS',
    method: 'PUT',
    endpoint: '/tasks/' + task_id + '/status',
    data: {
      status,
    },
  });
};

export const updateTaskPriority = (task_id, priority) => {
  return store.dispatch({
    action: 'table.all_tasks/UPDATE_TASK_PRIORITY',
    method: 'PUT',
    endpoint: '/tasks/' + task_id + '/priority',
    data: {
      priority,
    },
  });
};

export const generateTasksReport = (formData) => {
  return store.dispatch({
    action: 'table.all_tasks/GENERATE_TASKS_REPORT',
    method: 'POST',
    endpoint: '/tasks/export/pdf',
    data: formData,
  });
};

export const listPossiblePlanVersionTasks = (plan_version_id) => {
  return store.dispatch({
    action: 'table.all_tasks/LIST_POSSIBLE_PLAN_VERSION_TASKS',
    method: 'GET',
    endpoint: '/plans/versions/' + plan_version_id + '/possible_tasks',
  });
};

export const listPossiblePlanRevisionTasks = (plan_revision_id) => {
  return store.dispatch({
    action: 'table.all_tasks/LIST_POSSIBLE_PLAN_REVISION_TASKS',
    method: 'GET',
    endpoint: '/plans/revisions/' + plan_revision_id + '/possible_tasks',
  });
};

export const attachTaskToPlanRevision = (plan_revision_id, formData) => {
  return store.dispatch({
    action: 'table.all_tasks/ATTACH_TASK_TO_PLAN_REVISION',
    method: 'POST',
    endpoint: '/plans/revisions/' + plan_revision_id + '/attach_task',
    data: formData,
  });
};

export const attachTaskToPlanVersion = (plan_version_id, formData) => {
  return store.dispatch({
    action: 'table.all_tasks/ATTACH_TASK_TO_PLAN_VERSION',
    method: 'POST',
    endpoint: '/plans/versions/' + plan_version_id + '/attach_task',
    data: formData,
  });
};

export const downloadTaskFiles = (task_id) => {
  return downloadFile(backendURL + `/tasks/${task_id}/download`, [], 'POST');
};

export const multipleEditTaskTags = (task_ids, tags) => {
  return store.dispatch({
    action: 'table.all_tasks/MULTIPLE_EDIT_TASK_TAGS',
    method: 'PUT',
    endpoint: '/tasks/tags',
    data: {
      tags,
      tasks: task_ids,
    },
  });
};
